import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";
import { Text, Title as Heading } from "../typography/text.component";
import { Link } from "react-router-dom";
import { colors } from "../../infrastructure/theme/colors";

export const ExternalContainer = styled(FlexibleDiv)`
  background: #eeede7;
  justify-content: center;
  align-items: center;
  padding: 0;

  /* margin-top: 2rem; */
`;

export const FormSectionContainer = styled(FlexibleDiv)`
  /* background: #eeede7; */
  height: max-content;
  padding: 2rem 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.4rem;
  width: 86%;
  @media (max-width: 1000px) {
    width: 95%;
  }
  @media (max-width: 750px) {
    align-items: center;
  }
`;

export const Title = styled(Heading)`
  font-family: "Oswald";
  font-size: 2.2rem;
  letter-spacing: 0px;
  color: red;
  text-transform: uppercase;
  width: 100%;
  text-align: left;
`;

export const TabSectionWrapper = styled(FlexibleDiv)`
  /* background: green; */
  padding: 0;
  gap: 1.4rem;
  /* @media (max-width: 800px) {
    flex-direction: row;
  } */
`;

export const TabWrapper = styled(FlexibleDiv)`
  /* background: blue; */
  padding: 0;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "space-between"};
  gap: 0.7rem;

  @media (max-width: 800px) {
    flex-direction: row;
  }
`;

export const TabButtonWrapper = styled(FlexibleDiv)`
  // background: red;
  width: 48%;
  padding: 0;
  // justify-content: flex-start;
`;

export const AmountWrapper = styled(FlexibleDiv)`
  //  background: blue;
  padding: 0;
  width: 100%;
  flex-direction: column;
  /* justify-content: flex-start; */
  gap: 1rem;
  @media (max-width: 800px) {
    flex-direction: row;
  }
`;

export const AmountButtonWrapper = styled(FlexibleDiv)`
  //  background: red;
  width: 23%;
  min-width: 110px;
  padding: 0;
  // justify-content: flex-start;
`;

export const Plans = styled(FlexibleDiv)`
  /* background: blue; */
  padding: 0;
  width: 100%;
  // flex-wrap:wrap;
  justify-content: flex-start;
  gap: 0.5rem;
  @media (max-width: 800px) {
    flex-direction: row;
  }
`;

export const OthersWrapper = styled(FlexibleDiv)`
  //  background: blue;
  padding: 0;
  width: 100%;
  // flex-wrap:wrap;
  justify-content: flex-start;
  gap: 0.5rem;
  @media (max-width: 800px) {
    flex-direction: row;
  }
`;

export const NewWrapper = styled(FlexibleDiv)`
  //  background: blue;
  padding: 0;
  /* flex-grow: 1; */
  width: max-content;
  // flex-wrap:wrap;
  justify-content: flex-start;
  gap: 0.5rem;
  @media (max-width: 800px) {
    flex-direction: row;
  }
`;

export const PaymentCardWrapper = styled(FlexibleDiv)`
  background: ${({ background }) =>
    background ? background : "rgba(150,75,0,0.3)"};
  padding: 0.5rem;
  width: max-content;
  justify-content: flex-start;
  gap: 0.5rem;
  border-radius: 0.3rem;
  :hover {
    cursor: pointer;
  }

  @media (max-width: 800px) {
    flex-direction: row;
  }
`;

export const CardImage = styled.img`
  /* background: blue; */
  /* padding: 0.3rem; */
  height: 30px;
  /* border-radius: 50%; */
`;

export const CardName = styled(Text)`
  /* background: green; */
  font-size: 1.2rem;
  font-weight: bold;

  color: ${({ color }) => (color ? color : "#000")};
`;

export const Wrapperx = styled(FlexibleDiv)`
  /* background: blue; */
  padding: 0;
  flex-direction: column;
  /* width: 100%; */
  // flex-wrap:wrap;
  align-items: flex-start;
  gap: 1rem;
`;

export const PaymentDetailsWrapper = styled(FlexibleDiv)`
  /* background: blue; */
  padding: 0;
  width: 100%;
  // flex-wrap:wrap;
  justify-content: center;
  gap: 0.5rem;
  @media (max-width: 800px) {
    flex-direction: row;
  }
`;

export const DetailsFrame = styled(FlexibleDiv)`
  background: ${({ background }) => (background ? background : "transparent")};
  padding: 1rem;
  flex-grow: 1;
  width: ${({ width }) => (width ? width : "max-content")};
  max-width: 100%;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid #000;
  cursor: pointer;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  // flex-wrap:wrap;
  justify-content: center;
  /* gap: 0.5rem; */
`;

export const DetailsText = styled(Text)`
  font-size: 1.2rem;
  width: ${({ width }) => (width ? width : "100%")};
  text-align: center;
  overflow: hidden;
  font-weight: normal;
  color: ${({ color }) => (color ? color : colors.black.strong)};
  text-decoration: ${({ decoration }) => (decoration ? decoration : "none")};
`;

export const BodySection = styled(FlexibleDiv)`
  /* background: blue; */
  padding: 0;
  gap: 1.4rem;
  margin-top: 2rem;
`;

export const BodyText = styled(Text)`
  font-size: 1.2rem;
  font-weight: normal;
  font-style: italic;
  color: ${colors.black.regular};
`;

export const Form = styled.form`
  /* background: green; */
  padding: 0;
  display: flex;
  width: 100%;
  gap: ${({ secondForm }) => (secondForm ? "1rem" : "0.5rem")};
  align-items: flex-end;
  flex-direction: ${({ secondForm }) => (secondForm ? "column" : "row")};
  @media (max-width: 800px) {
    gap: 1rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;
export const Wrapper = styled(FlexibleDiv)`
  /* background: red; */
  width: ${({ secondForm }) => (secondForm ? secondForm : "100%")};
  padding: 0;
  justify-content: flex-start;
  /* gap: 0.5rem; */
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const NoneWrapper = styled(FlexibleDiv)`
  /* background: red; */
  display: none;
  width: ${({ secondForm }) => (secondForm ? secondForm : "100%")};
  padding: 0;
  justify-content: flex-start;
  /* gap: 0.5rem; */
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const DoubleFormWrapper = styled(FlexibleDiv)`
  /* background: red; */
  width: 100%;
  padding: 0;
  justify-content: space-between;
  /* gap: 0.5rem; */
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const ButtonWrapper = styled(FlexibleDiv)`
  /* background: red; */
  width: max-content;
  padding: 0;
  justify-content: flex-start;
`;

export const ButtonsWrapper = styled(FlexibleDiv)`
  /* background: red; */
  width: 100%;
  gap: 1rem;
  padding: 0;
  justify-content: space-between;
`;
export const BulletPoints = styled(FlexibleDiv)`
  background: #fff;
  padding: 0.3rem;
  width: max-content;
  border-radius: 50%;
`;
export const BulletPointsText = styled(Heading)`
  font-weight: bold;
  color: #fff;
  font-size: 1.1rem;
  line-height: 1.2rem;
  word-spacing: 0.2rem;
`;

export const StyledLink = styled(Link)`
  font-size: 1.1rem;
  font-weight: 600;
  color: #ffb300;
  margin: 0 0.3rem;
  text-decoration: underline;
`;
