import React from "react";
import {
  VerticalCardContainer,
  CardImageWrapper,
  CardImage,
  Title,
  BigTitle,
  ButtonWrapper,
  PreNewsContainer,
  SecondPreNewsContainer,
  Container,
  StyledText,
} from "./verticalCard.styles";
import { Button } from "../button/button.component";
import CardImage1 from "../../assets/images/CardImage1.jpg";
import CardImage2 from "../../assets/images/CardImage2.jpg";
import CardImage3 from "../../assets/images/CardImage3.jpg";

import Image1 from "../../assets/images/Image1.jpg";
import Image2 from "../../assets/images/Image2.jpg";
import Image3 from "../../assets/images/Image3.jpg";
import Image4 from "../../assets/images/Image4.jpg";
import Image5 from "../../assets/images/Image5.jpg";
import Image6 from "../../assets/images/Image6.jpg";

import celeb1 from "../../assets/firstladyofmotown-20240319_160700-433100098_18421640557038679_962032053143247181_n.jpeg";
import celeb2 from "../../assets/alisonkrauss-20160427_112356-13113869_1520532958255725_2123198948_n.jpeg";
import celeb3 from "../../assets/dollyparton-20240628_125030-449510111_1204275510697059_3187471196358969681_n(1).jpeg";
import celeb4 from "../../assets/thewarrenhaynes-20201109_113712-124019351_120512172993526_7434262735507791565_n.jpeg";
import celeb5 from "../../assets/mrpeterframpton-20230830_103605-370268171_867597551394827_3709305092756172307_n.jpeg";
import celeb6 from "../../assets/dianaross-20210717_190259-219519404_195828782502997_984337452494722656_n.jpeg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";


export const VerticalCard = ({ image, buttonText, title, short }) => {

  const navigation = useNavigate();
  return (
    <VerticalCardContainer>
      <CardImageWrapper>
        <CardImage src={image} short={short} />
      </CardImageWrapper>
      <Title>
        {title
          ? title
          : "Join us to learn how you can help make change for children."}
      </Title>
      <ButtonWrapper>
        {buttonText &&
          (buttonText==="Read More" || buttonText==="Learn More" ? (
            <Link
              activeClass="active"
              to="contactForm"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              onSetActive={()=>{
                console.log("Activated")
              }}
            >
              <Button
                fontSize={"22px"}
                btnText={buttonText ? buttonText : "Join Us"}
                background={"#009ca6"}
                width={"100%"}
              
              />
            </Link>
          ) : (
            <Button
              fontSize={"22px"}
              btnText={buttonText ? buttonText : "Join Us"}
              background={"#009ca6"}
              width={"100%"}
              onClick={() => {
                navigation("/donate");
              }}
            />
          ))}
      </ButtonWrapper>
    </VerticalCardContainer>
  );
};

export const SecondVerticalCard = ({ image, buttonText, title }) => { 
  const navigation = useNavigate()
  return (
    <VerticalCardContainer second>
      <CardImageWrapper second>
        <CardImage src={image} second />
      </CardImageWrapper>
      <Title second>
        {title
          ? title
          : "Join us to learn how you can help make change for children."}
      </Title>
      <ButtonWrapper second>
        <Button
          fontSize={"22px"}
          btnText={"Join Today"}
          // background={"#009ca6"}
          width={"100%"}
           onClick={()=>{navigation("/donate")}}
        />
      </ButtonWrapper>
    </VerticalCardContainer>
  );
};

export const Prenews = () => {
  const preNewsData = [
    {
      image: CardImage1,
      title:
        "NEARLY EVERY SURVIVING CHILD IN GAZA IS NOW IN THE LINE OF FIRE IN RAFAH",
      buttonText: "Learn More",
    },
    {
      image: CardImage2,
      title:
        "HURRICANE BERYL: OUR RELIEF RESPONSE AND HOW YOUR DONATION CAN HELP",
      buttonText: "Read More",
    },
    {
      image: CardImage3,
      title:
        "WHEN SCHOOL IS OUT FOR SUMMER, KIDS LIVING IN POVERTY CAN FALL BEHIND",
      buttonText: "Learn More",
    },
  ];

  return (
    <PreNewsContainer>
      {preNewsData.map(({ image, title, buttonText }) => (
        <VerticalCard
          image={image}
          title={title}
          buttonText={buttonText}
          goToContact={true}
        />
      ))}
    </PreNewsContainer>
  );
};

export const SecondPrenews = () => {
  const preNewsData = [
    {
      image: Image1,
      title: "BECOME A MONTHLY DONOR",
      buttonText: "Give Now",
    },
    {
      image: Image2,
      title: "HODL HOPE FOR CHILDREN",
      buttonText: "Donate Crypto",
    },
    {
      image: Image3,
      title: "BROWSE THE GIFT CATALOG",
      buttonText: "Browse Gifts",
    },

    {
      image: Image4,
      title: "DONOR ADVISED FUNDS",
      buttonText: "Donate Now",
    },
    {
      image: Image5,
      title: "FUNDRAISE FOR KIDS",
      buttonText: "FUNDRAISE Now",
    },
    {
      image: Image6,
      title: "HOW TO HELP CHILDREN",
      buttonText: "Learn More",
    },
  ];

  return (
    <Container>
      <BigTitle>
        Join us to learn how you can help make change for children.
      </BigTitle>
      <SecondPreNewsContainer>
        {preNewsData.map(({ image, title, buttonText, gotoDonate = true }) => (
          <VerticalCard
            gotoDonate
            image={image}
            title={title}
            buttonText={buttonText}
          />
        ))}
      </SecondPreNewsContainer>
    </Container>
  );
};

export const ThirdPrenews = () => {
  const preNewsData = [
    {
      image: Image1,
      title: "Stand against inequality for children around the world.",
      buttonText: "Give Now",
    },
    {
      image: Image2,
      title:
        "Address the climate crisis to create a safer planet for future generations.",
      buttonText: "Donate Crypto",
    },
    {
      image: Image3,
      title: "Help protect children affected by war and conflict.",
      buttonText: "Browse Gifts",
    },

    {
      image: Image4,
      title:
        "Help children impacted by poverty in the U.S. access the resources they deserve.",
      buttonText: "Donate Now",
    },
  ];

  return (
    <Container second>
      <BigTitle>Become a Monthly Donor by Joining Team Tomorrow</BigTitle>
      <StyledText>
        Team Tomorrow is a way to connect with the causes you care about through
        a monthly giving program. Choose a cause that matters to you most, and
        your recurring gift will go toward addressing the needs of children in
        the U.S. and around the world.
      </StyledText>
      <SecondPreNewsContainer>
        {preNewsData.map(({ image, title, buttonText }) => (
          <SecondVerticalCard
            image={image}
            title={title}
            buttonText={buttonText}

          />
        ))}
      </SecondPreNewsContainer>
    </Container>
  );
};


export const PartnerPrenews = () => {
  const preNewsData = [
    {
      image: celeb1,
      title: "CLAUDETTE ROGERS",
    },
    {
      image: celeb2,
      title: "ALLISON KRAUSS",
    },
    {
      image: celeb3,
      title: "DOLLY PARTON",
    },
    {
      image: celeb4,
      title: "WARREN HAYNES",
    },
    {
      image: celeb5,
      title: "PETER FRAMPTON",
    },
    {
      image: celeb6,
      title: "DIANA ROSS",
    },
  ];

  return (
    <PreNewsContainer>
      {preNewsData.map(({ image, title, buttonText }) => (
        <VerticalCard short={true} image={image} title={title} />
      ))}
    </PreNewsContainer>
  );
};
