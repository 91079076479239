import React, { useState, useRef /* useEffect */ } from "react";
import emailjs from "@emailjs/browser";
import {
  ExternalContainer,
  FormSectionContainer,
  Title,
  TabSectionWrapper,
  TabWrapper,
  TabButtonWrapper,
  AmountWrapper,
  AmountButtonWrapper,
  BodySection,
  BodyText,
  Form,
  DoubleFormWrapper,
  Wrapper,
  ButtonWrapper,
  PaymentCardWrapper,
  CardImage,
  CardName,
  PaymentDetailsWrapper,
  DetailsFrame,
  DetailsText,
  Wrapperx,
  NoneWrapper,
  Plans,
  OthersWrapper,
  NewWrapper,
  ButtonsWrapper,
} from "./donateFormSection.styles";
import { Input } from "../input/input.component";
import { Button } from "../button/button.component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import card1 from "../../assets/visa_small.gif";
import card2 from "../../assets/amex_small.gif";
import card3 from "../../assets/mastercd_small.gif";
import card4 from "../../assets/discovercard_sm.gif";
import paypal from "../../assets/paypal-logo.png";
import CashApp from "../../assets/cash-app-real.webp";
import Venmo from "../../assets/venmo-logo.png";
import Zelle from "../../assets/zell.jpg";
import BTC from "../../assets/btc2.png";
import Ethereum from "../../assets/ethereum.webp";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { fontWeights } from "../../infrastructure/theme/fonts";

export const DonateFormSection = () => {
  const [file, setFile] = useState("");
  const [email, setEmail] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [country, setCountry] = useState("");
  const [phone_number, setPhone_number] = useState("");

  const [nextPage, setNextPage] = useState(false);

  const [billingCycle, setBillingCycle] = useState("MONTHLY");
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("PayPal");
  const [customAmount, setCustomAmount] = useState("");
  const [error, setError] = useState(false);

  // useEffect(() => {
  //   console.log("full_name:", full_name);
  // }, [full_name]);

  const handleFile = (e) => setFile(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handleFirst_name = (e) => setFirst_name(e.target.value);
  const handleLast_name = (e) => setLast_name(e.target.value);
  const handleCountry = (e) => setCountry(e.target.value);
  const handlePhone_number = (e) => setPhone_number(e.target.value);
  const handleCustomAmount = (e) => setCustomAmount(e.target.value);

  const notify = () => {
    toast.success("Thank you for helping Isreal!", {
      position: "top-center",
    });
  };

  const errorHandling = (message) => {
    toast.error(message, {
      position: "top-right",
    });
  };

  //  const [data, setData] = useState({
  //    file,
  //    email,
  //    first_name,
  //    last_name,
  //    country,
  //    phone_number,
  //    billingCycle,
  //    amount,
  //    paymentMethod,
  //  });

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_0n2jfpg", "template_uso7h8f", form.current, {
        publicKey: "xDlZeW_9-7box-Cv3",
      })
      .then(
        (result) => {
          console.log("Result:", result.text);
          setFile("");
          setEmail("");
          setFirst_name("");
          setLast_name("");
          setCountry("");
          setPhone_number("");
          setAmount("");
          notify();
        },
        (error) => {
          console.log("Its not working", error.text);
        }
      );
  };
  const [copied, setCopied] = useState(false);

  const monthlyAmount = [
    { price: "$15" },
    { price: "$25" },
    { price: "$35" },
    { price: "$50" },
    { price: "$75" },
    { price: "$100" },
  ];

  const oneTimeAmount = [
    { price: "$50" },
    { price: "$100" },
    { price: "$150" },
    { price: "$300" },
    { price: "$500" },
    { price: "$1000" },
  ];

  const paymentMethods = [
    {
      paymentCards: [
        { cardImage: card1 },
        { cardImage: card2 },
        { cardImage: card3 },
        { cardImage: card4 },
      ],
    },
    {
      paymentCards: paypal,
      cardName: "PayPal",
      details: "Terirjohbson@hotmail.com",
    },
    { paymentCards: Zelle, cardName: "Zelle", details: "1 (302) 893-7617" },
    { paymentCards: CashApp, cardName: "CashApp", details: "$RecoveryRow" },
    {
      paymentCards: Venmo,
      cardName: "Venmo",
      details: "Terirjohbson@hotmail.com",
    },
    {
      paymentCards: Ethereum,
      cardName: "Ethereum",
      details: "0xD80F2b79AdE9136F42ae2D1938fdDA4FE610F824",
    },

    {
      paymentCards: BTC,
      cardName: "Btc",
      details: "bc1qnyjuf6epyyaust7sms2gzppdu4fjxa9jdzyw49",
    },
  ];

  return (
    <ExternalContainer>
      <FormSectionContainer>
        {/* <Title> 1. Choose Your Gift Type </Title> */}
        {!nextPage ? (
          <React.Fragment>
            <TabSectionWrapper>
              <Title> 1. Choose Your Gift Type </Title>
              <TabWrapper>
                <TabButtonWrapper>
                  <Button
                    paddingVertical={"1rem"}
                    btnText="MONTHLY"
                    background={
                      billingCycle === "MONTHLY" ? "#009ca6" : "#D1CCBD"
                    }
                    btnColor={billingCycle === "MONTHLY" ? "#fff" : "#000"}
                    fontSize={"2rem"}
                    width={"100%"}
                    onClick={() => {
                      setBillingCycle("MONTHLY");
                    }}
                  />
                </TabButtonWrapper>

                <TabButtonWrapper>
                  <Button
                    paddingVertical={"1rem"}
                    btnText="ONE TIME"
                    background={
                      billingCycle === "ONE TIME" ? "#009ca6" : "#D1CCBD"
                    }
                    fontSize={"2rem"}
                    width={"100%"}
                    btnColor={billingCycle === "ONE TIME" ? "#fff" : "#000"}
                    onClick={() => {
                      setBillingCycle("ONE TIME");
                    }}
                  />
                </TabButtonWrapper>
              </TabWrapper>
            </TabSectionWrapper>

            <TabSectionWrapper>
              <Title> 2. Choose Your Gift Amount </Title>
              <AmountWrapper>
                <Plans>
                  {billingCycle === "MONTHLY"
                    ? monthlyAmount.map(({ price }) => (
                        <AmountButtonWrapper>
                          <Button
                            paddingVertical={"1rem"}
                            btnText={price}
                            background={
                              amount === price ? "#009ca6" : "#D1CCBD"
                            }
                            fontSize={"2rem"}
                            btnColor={amount === price ? "#fff" : "#000"}
                            width={"100%"}
                            onClick={() => {
                              setAmount(price);
                            }}
                          />
                        </AmountButtonWrapper>
                      ))
                    : oneTimeAmount.map(({ price }) => (
                        <AmountButtonWrapper>
                          <Button
                            paddingVertical={"1rem"}
                            btnText={price}
                            background={
                              amount === price ? "#009ca6" : "#D1CCBD"
                            }
                            fontSize={"2rem"}
                            btnColor={amount === price ? "#fff" : "#000"}
                            width={"100%"}
                            onClick={() => {
                              setAmount(price);
                            }}
                          />
                        </AmountButtonWrapper>
                      ))}
                </Plans>

                <OthersWrapper>
                  <Button
                    paddingVertical={"1rem"}
                    textTransform={"capitalize"}
                    btnText={"Others"}
                    background={amount === "other" ? "#009ca6" : "#D1CCBD"}
                    fontSize={"2rem"}
                    btnColor={amount === "other" ? "#fff" : "#000"}
                    width={"23%"}
                    onClick={() => {
                      setAmount("other");
                    }}
                  />

                  {amount === "other" && (
                    <NewWrapper>
                      <Input
                        type="number"
                        fontSize={"2rem"}
                        placeholder={"Type Amount"}
                        padding={"0.5rem 1rem"}
                        fontWeight={fontWeights.bold}
                        name="custom_amount"
                        value={customAmount}
                        onChange={handleCustomAmount}
                        background={"#D1CCBD"}
                      />
                    </NewWrapper>
                  )}
                </OthersWrapper>
              </AmountWrapper>
            </TabSectionWrapper>

            <TabSectionWrapper>
              <Title> 3. Enter Your Payment Information </Title>
              <TabWrapper justifyContent={"flex-start"}>
                {paymentMethods.map(({ paymentCards, cardName, details }) => (
                  <PaymentCardWrapper
                    onClick={() => {
                      setPaymentMethod({
                        cardName: cardName,
                        details: details,
                      });
                      setCopied(false);
                    }}
                    background={
                      paymentMethod.cardName === cardName
                        ? "#009ca6"
                        : "rgba(150,75,0,0.3)"
                    }
                  >
                    {Array.isArray(paymentCards) ? (
                      paymentCards.map(({ cardImage }) => (
                        <CardImage src={cardImage} alt="paymentCard" />
                      ))
                    ) : (
                      <CardImage src={paymentCards} alt="paymentCard" />
                    )}
                    {cardName && (
                      <CardName
                        color={
                          paymentMethod.cardName === cardName ? "#fff" : "#000"
                        }
                      >
                        {cardName}
                      </CardName>
                    )}
                  </PaymentCardWrapper>
                ))}
              </TabWrapper>
            </TabSectionWrapper>

            <TabSectionWrapper>
              {paymentMethod.cardName ? (
                <Wrapperx>
                  <PaymentDetailsWrapper>
                    <CopyToClipboard
                      text={paymentMethod.details}
                      onCopy={() => setCopied(true)}
                    >
                      <DetailsFrame>
                        <DetailsText>{paymentMethod.details}</DetailsText>
                      </DetailsFrame>
                    </CopyToClipboard>

                    <CopyToClipboard
                      text={paymentMethod.details}
                      onCopy={() => setCopied(true)}
                    >
                      <DetailsFrame background={"#009ca6"}>
                        <DetailsText width={"max-content"} color={"#fff"}>
                          Copy
                        </DetailsText>
                      </DetailsFrame>
                    </CopyToClipboard>

                    {copied ? (
                      <span style={{ color: "red" }}>Copied</span>
                    ) : null}
                  </PaymentDetailsWrapper>

                  <DetailsText color={"#009ca6"}>
                    Use the details above for your{" "}
                    {amount !== "other" ? amount : `$${customAmount}`}{" "}
                    {paymentMethod.cardName} payment
                  </DetailsText>
                </Wrapperx>
              ) : (
                <PaymentDetailsWrapper>
                  <DetailsText decoration={"underline"}>
                    Select Another Payment Method
                  </DetailsText>
                </PaymentDetailsWrapper>
              )}
            </TabSectionWrapper>

            <TabSectionWrapper>
              <Title> 4. Completed the Payment? Upload Proof </Title>

              <Wrapper>
                <Input
                  required
                  type="file"
                  label="*Upload Picture Proof of Payment"
                  name="proof"
                  value={file}
                  onChange={handleFile}
                />
              </Wrapper>
            </TabSectionWrapper>

            <ButtonWrapper>
              <Button
                btnText="Continue"
                background={"#009ca6"}
                onClick={() => {
                  // console.log("file", file);
                  amount === "" || (amount === "other" && customAmount === "")
                    ? errorHandling(
                        "Make Sure to choose or input your Gift Amount!!!"
                      )
                    : file === ""
                    ? errorHandling("Make Sure to Upload proof of donation!!!")
                    : setNextPage(true);
                }}
              />
            </ButtonWrapper>
          </React.Fragment>
        ) : (
          <BodySection>
            <Form secondForm ref={form} onSubmit={sendEmail}>
              <Title> 4. Enter your billing information </Title>
              <Wrapper>
                <Input
                  required
                  type="email"
                  label="*Email Address"
                  name="email"
                  value={email}
                  onChange={handleEmail}
                />
              </Wrapper>
              <DoubleFormWrapper>
                <Wrapper secondForm="49%">
                  <Input
                    required
                    type="text"
                    label="*First Name"
                    name="first_name"
                    value={first_name}
                    onChange={handleFirst_name}
                  />
                </Wrapper>
                <Wrapper secondForm="49%">
                  <Input
                    required
                    type="text"
                    label="*Last Name"
                    name="last_name"
                    value={last_name}
                    onChange={handleLast_name}
                  />
                </Wrapper>
              </DoubleFormWrapper>

              <Wrapper secondForm>
                <Input
                  required
                  type="text"
                  label="Country"
                  name="country"
                  placeholder={""}
                  value={country}
                  onChange={handleCountry}
                />
              </Wrapper>

              <Wrapper secondForm>
                <Input
                  required
                  type="phone"
                  label="
Mobile Phone Number (optional)"
                  name="phone_number"
                  placeholder={"(555) 555-5555"}
                  value={phone_number}
                  onChange={handlePhone_number}
                />
              </Wrapper>

              <NoneWrapper>
                <Input
                  required
                  type="text"
                  name="picture"
                  placeholder={""}
                  value={file}
                />
                <Input
                  required
                  type="text"
                  name="amount"
                  placeholder={""}
                  value={amount !== "other" ? amount : `$${customAmount}`}
                />

                <Input
                  required
                  type="text"
                  name="billing_cycle"
                  placeholder={""}
                  value={billingCycle}
                />

                <Input
                  required
                  type="text"
                  name="Payment_method"
                  placeholder={""}
                  value={paymentMethod}
                />
              </NoneWrapper>

              <BodyText>
                By providing my mobile phone number, I agree to receive
                recurring text messages from Save the Children (48188) and phone
                calls with opportunities to donate and ways to engage in our
                mission to support children around the world. Text STOP to
                opt-out, HELP for info. Message & data rates may apply. View our
                Privacy Policy at savethechildren.org/privacy.
              </BodyText>

              <ButtonsWrapper>
                <Button
                  btnText="Submit"
                  width={"40%"}
                  background={"#009ca6"}
                  onClick={() =>
                    email === ""
                      ? errorHandling("Input a valid Email")
                      : first_name === ""
                      ? errorHandling("Input your First Name")
                      : last_name === ""
                      ? errorHandling("Input your Last Name")
                      : country === ""
                      ? errorHandling("Input your Country")
                      : phone_number === ""
                      ? errorHandling("Input your Phone Number")
                      : sendEmail
                  }
                />

                <Button
                  btnText="Go Back"
                  width={"40%"}
                  background={"red"}
                  onClick={() => {
                    setNextPage(false);
                  }}
                />
              </ButtonsWrapper>
            </Form>
          </BodySection>
        )}
      </FormSectionContainer>
    </ExternalContainer>
  );
};
