import styled from "styled-components";
import { FlexibleDiv } from "../../components/flexibleDiv/flexibleDiv.component";
import DonateBgImage from "../../assets/images/donatbg.jpg";



export const ExternalContainer = styled(FlexibleDiv)`
  background: url(${({ bgImage }) => (bgImage ? bgImage : DonateBgImage)});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  padding: 0;
  overflow-y: scroll;
  overflow-x:hidden;
`;
export const InnerContainer = styled(FlexibleDiv)`
  /* background: blue; */
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start ;
  height: max-content;
  padding: 0;
`;
export const Container = styled(FlexibleDiv)`
  /* background: red; */
  padding: 5.5vw;
  flex-direction: column;
  justify-content: center;
  height: max-content;
  width:55%;
  gap: 2rem;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 2vw;
  }
`;