import React from "react";
import { ExternalContainer, InnerContainer, Container } from "./donate.styles";
import { SecondHomeSecondSectionCard } from "../../components/homeSecondSectionCard/homeSecondSectionCard.section";
import Footer from "../../components/footer/footer.component";
import Header from "../../components/header/header.component";
import { DonateFormSection } from "../../components/donateFormSection/donateFormSection.component";
import { ToastContainer } from "react-toastify";

const Donate = () => {
  return (
    <ExternalContainer>
      <InnerContainer>
        <Header />
        <Container>
          <SecondHomeSecondSectionCard />
          <DonateFormSection />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Container>
        <Footer />
      </InnerContainer>
    </ExternalContainer>
  );
};

export default Donate;
