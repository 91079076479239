import styled from "styled-components";
import { FlexibleDiv } from "../../components/flexibleDiv/flexibleDiv.component";
import { Title } from "../../components/typography/text.component";

export const Container = styled(FlexibleDiv)`
  /* background: red; */
  padding:0;
  flex-direction: column;
  justify-content: center;
`;

export const Wrapper = styled(FlexibleDiv)`
  background: #eeede7;
  padding: 2rem 0;
  width: 86%;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
 
  @media (max-width: 1000px) {
    width: 95%;
  }
`;
export const Words = styled(Title)`
  font-size: 2.3rem;
  width:80%;
  /* width: ${({ width }) => (width ? width : "100%")}; */
  text-align: center;
  /* overflow: hidden; */
  text-transform: uppercase;
  font-weight: bolder;
  color: #009ca6;
`;

