import React from "react";
import { Container, Wrapper, Words } from "./home.styles";
import { HeroSection } from "../../components/heroSection/heroSection.component";
import { HomeSecondSectionCard } from "../../components/homeSecondSectionCard/homeSecondSectionCard.section";
import { FormSection } from "../../components/formSection/formSection.component";
import {
  Prenews,
  SecondPrenews,
} from "../../components/verticalCard/verticalCard.component";
import { News } from "../../components/horizontalCard/horizontalCard.component";
import { BottomFormSection } from "../../components/bottomFormSection/bottomFormSection.component";
import { FeaturedPartners } from "../../components/featuredPartners/featuredPartners.component";
import Footer from "../../components/footer/footer.component";
import Header from "../../components/header/header.component";
import { ToastContainer } from "react-toastify";
import { Button } from "../../components/button/button.component";
import { useNavigate } from "react-router-dom";
import { BiSolidDonateHeart as DonateIcon} from "react-icons/bi";




const Home = () => {
  
  const navigate = useNavigate();
  return (
    <Container>
      <Header />
      <HeroSection />
      <HomeSecondSectionCard />
      <FormSection />
      <Wrapper>
        <Words>You can Join Us and stand with Israel Today</Words>
        <Button
          LeftIcon={DonateIcon}
          paddingVertical={"1rem"}
          textTransform={"capitalize"}
          btnText={"BLESS ISRAEL NOW"}
          background={"#009ca6"}
          fontSize={"2rem"}
          width={"40%"}
          resfontSize={"1.3rem"}
          onClick={() => {
            navigate("/donate");
          }}
        />
      </Wrapper>
      <Prenews />
      <News />
      <SecondPrenews />
      <FeaturedPartners />
      <BottomFormSection />
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Container>
  );
};

export default Home;
