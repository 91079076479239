import React, { useState, useRef, /* useEffect */ } from "react";
import emailjs from "@emailjs/browser";
import {
  ExternalContainer,
  FormSectionContainer,
  Title,
  BodySection,
  BodyText,
  Form,
  Wrapper,
  ButtonWrapper,
} from "./bottomFormSection.styles";
import { Input, TextArea } from "../input/input.component";
import { Button } from "../button/button.component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const BottomFormSection = () => {
  const [full_name, setFull_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhone_number] = useState("");

  const handleFull_name = (e) => setFull_name(e.target.value);
  const handleLast_name = (e) => setLast_name(e.target.value);
  const handlePhone_number = (e) => setPhone_number(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);

  const notify = () => {
    toast.success("Thank you for sending us your Message!", {
      position: "top-center",
    });

    // toast.onChange((payload) => {
    //   if (payload.status === "removed") {
    //     navigate("/success");
    //   }
    // });
    // toast("Thank you for joining the Waitlist!");

    // toast.error("Error Notification !", {
    //   position: toast.POSITION.TOP_LEFT,
    // });

    // toast.warn("Warning Notification !", {
    //   position: toast.POSITION.BOTTOM_LEFT,
    // });

    // toast.info("Info Notification !", {
    //   position: toast.POSITION.BOTTOM_CENTER,
    // });

    // toast("Custom Style Notification with css class!", {
    //   position: toast.POSITION.BOTTOM_RIGHT,
    //   className: "foo-bar",
    // });
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_0n2jfpg", "template_sv4mq3k", form.current, {
        publicKey: "xDlZeW_9-7box-Cv3",
      })
      .then(
        (result) => {
          console.log("Result:", result.text, form.current);
          setFull_name("");
          setLast_name("");
          setEmail("");
          setPhone_number("");
          notify();
        },
        (error) => {
          console.log("Its not working", error.text);
        }
      );
  };




  return (
    <ExternalContainer name="contactForm">
      <FormSectionContainer>
        <Title>Sign Up & Stay Connected</Title>
        <BodySection>
          <Form ref={form} onSubmit={sendEmail}>
            <Wrapper>
              <Input
                label="*First Name"
                type="text"
                name="first_name"
                value={full_name}
                onChange={handleFull_name}
              />
            </Wrapper>
            <Wrapper>
              <Input
                label="*Last Name"
                type="text"
                name="last_name"
                value={last_name}
                onChange={handleLast_name}
              />
            </Wrapper>
            <Wrapper>
              <Input
                label="*Email Address"
                type="email"
                name="email"
                value={email}
                onChange={handleEmail}
              />
            </Wrapper>
            <Wrapper>
              <Input
                label="Mobile Number"
                placeholder={"(555) 555-5555"}
                type="number"
                name="phone_number"
                value={phone_number}
                onChange={handlePhone_number}
              />
            </Wrapper>
            <ButtonWrapper>
              <Button btnText="Sign Up" background={"#009ca6"} />
            </ButtonWrapper>
          </Form>
          <BodyText>
            By providing my mobile phone number, I agree to receive recurring
            text messages from Save the Children (48188) and phone calls with
            opportunities to donate and ways to engage in our mission to support
            children around the world. Text STOP to opt-out, HELP for info.
            Message & data rates may apply. View our Privacy Policy at
            savethechildren.org/privacy.
          </BodyText>
        </BodySection>
      </FormSectionContainer>
    </ExternalContainer>
  );
};

export const ContactFormSection = () => {
  const [full_name, setFull_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [message, setMessage] = useState("");

// useEffect(() => {
//   console.log("full_name:", full_name);
// }, [full_name]);

  const handleFull_name = (e) => setFull_name(e.target.value);
  const handleLast_name = (e) => setLast_name(e.target.value);
  const handlePhone_number = (e) => setPhone_number(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handleMessage = (e) => setMessage(e.target.value);

  const notify = () => {
    toast.success("Thank you for sending us your Message!", {
      position: "top-center",
    });

    // toast.onChange((payload) => {
    //   if (payload.status === "removed") {
    //     navigate("/success");
    //   }
    // });
    // toast("Thank you for joining the Waitlist!");

    // toast.error("Error Notification !", {
    //   position: toast.POSITION.TOP_LEFT,
    // });

    // toast.warn("Warning Notification !", {
    //   position: toast.POSITION.BOTTOM_LEFT,
    // });

    // toast.info("Info Notification !", {
    //   position: toast.POSITION.BOTTOM_CENTER,
    // });

    // toast("Custom Style Notification with css class!", {
    //   position: toast.POSITION.BOTTOM_RIGHT,
    //   className: "foo-bar",
    // });
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_x3rvc3b", "template_gzl2ou3", form.current, {
        publicKey: "xSCZYvKfedHsD4s2V",
      })
      .then(
        (result) => {
          console.log("Result:", result.text, form.current);
          setFull_name("");
          setLast_name("");
          setEmail("");
          setPhone_number("");
          setMessage("");
          notify();
        },
        (error) => {
          console.log("Its not working", error.text);
        }
      );
  };

  return (
    <ExternalContainer>
      <FormSectionContainer>
        <Title>Contact Us</Title>
        <BodySection>
          <Form secondForm ref={form} onSubmit={sendEmail}>
            <Wrapper secondForm>
              <Input
                type="text"
                label="*First Name"
                name="first_name"
                value={full_name}
                onChange={handleFull_name}
              />
            </Wrapper>
            <Wrapper secondForm>
              <Input
                type="text"
                label="*Last Name"
                name="last_name"
                value={last_name}
                onChange={handleLast_name}
              />
            </Wrapper>
            <Wrapper secondForm>
              <Input
                type="email"
                label="*Email Address"
                name="email"
                value={email}
                onChange={handleEmail}
              />
            </Wrapper>
            <Wrapper secondForm>
              <Input
                type="number"
                label="Mobile Number"
                name="phone_number"
                placeholder={"(555) 555-5555"}
                value={phone_number}
                onChange={handlePhone_number}
              />
            </Wrapper>
            <Wrapper secondForm>
              <TextArea
                placeholder={"Message"}
                name="message"
                type={"text"}
                value={message}
                onChange={handleMessage}
              />
            </Wrapper>
            <ButtonWrapper>
              <Button
                btnText="Contact Us"
                background={"#009ca6"}
                // onClick={sendEmail}
              />
            </ButtonWrapper>
          </Form>
          <BodyText>
            By providing my mobile phone number, I agree to receive recurring
            text messages from Save the Children (48188) and phone calls with
            opportunities to donate and ways to engage in our mission to support
            children around the world. Text STOP to opt-out, HELP for info.
            Message & data rates may apply. View our Privacy Policy at
            savethechildren.org/privacy.
          </BodyText>
        </BodySection>
      </FormSectionContainer>
    </ExternalContainer>
  );
};
